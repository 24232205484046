// import dummyOfferTest from './dummy-offer-test';
// import dummyTourTest from './dummy-tour-test';
import yoga28DayChallengeFullBodyBeforeAfterImages from './yoga-28-day-challenge-full-body-before-after-images';
import pilates28DayChallengeCheckoutTestDifferentTitle from './pilates-28-day-challenge-checkout-test-different-title';
import pilates28DayChallengeUpdatedTargedZoneStep from './pilates-28-day-challenge-updated-targed-zone-step';

const tests = [
  // Dummy experiments
  // dummyOfferTest,
  // dummyTourTest,

  yoga28DayChallengeFullBodyBeforeAfterImages,
  pilates28DayChallengeCheckoutTestDifferentTitle,
  pilates28DayChallengeUpdatedTargedZoneStep,
];

export default tests;
