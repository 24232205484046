import { checkoutApi } from '@/modules/apis/checkout-api';

export const checkoutRepository = {
  getCheckoutIdByRegionAndProductWithVariant: async ({
    region,
    vertical,
    type,
    pricingModel,
    variant,
    variation,
    brand,
  }) => {
    const { data } = await checkoutApi.get('/checkout-id', {
      params: {
        region,
        vertical,
        type,
        pricingModel,
        variant,
        variation,
        brand,
      },
    });

    return data;
  },
};
