import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';

import { F040_IMAGES_HOSTS, RH_IMAGES_HOSTS } from '@/services/constants';

const IMAGE_HOSTS_MAP = {
  [PROJECT_BRANDS.fitover40]: F040_IMAGES_HOSTS,
  [PROJECT_BRANDS.reverse]: RH_IMAGES_HOSTS,
};

const IMAGE_CDN_MAP = {
  [PROJECT_BRANDS.fitover40]: 'https://fitover40.health/cdn-cgi/image/',
  [PROJECT_BRANDS.reverse]: 'https://reverse.health/cdn-cgi/image/',
};

export const getBrandByHost = ({ host }) => {
  if (host.includes('fitover40.health')) {
    return PROJECT_BRANDS.fitover40;
  }

  if (host.includes('reverse.health')) {
    return PROJECT_BRANDS.reverse;
  }

  return PROJECT_BRANDS.reverse;
};

export const getImageHostByBrand = (
  { brand, isCi, host } = { brand: PROJECT_BRANDS.reverse, isCi: false, host: '' },
) => {
  const selectedBrand = IMAGE_HOSTS_MAP[brand];

  if (isCi) return selectedBrand.STAGING;

  return host?.includes('localhost')
    ? selectedBrand.LOCALHOST
    : host?.includes('staging')
      ? selectedBrand.STAGING
      : selectedBrand.PRODUCTION;
};

export const getCdnHostByBrand = ({ brand } = { brand: PROJECT_BRANDS.reverse }) => {
  return IMAGE_CDN_MAP[brand];
};
