import {
  SET_AUTH_LOGIN_EMAIL,
  SET_AUTH_COMPONENT_NAME,
  SET_AUTH_SUGGESTIC_TOKEN,
  SET_AUTH_RESET_PASSWORD_PASSWORD,
  SET_AUTH_RESET_PASSWORD_TOKEN,
  SET_AUTH_MAGIC_LINK,
  SET_AUTH_MAGIC_LINK_FETCHING,
  SET_JWT_DATA,
  SET_IS_TAPPTITUDE_USER,
} from '../mutation-types';
import URLS from '@/services/urls';
import { Helpers } from '@/services/helpers';
import { COOKIES, MAGIC_LINK_VERSION } from '@/services/constants';

/**
 *  Check if user exist in firebase database
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - User email address
 * @return {Object} Contain user status
 */
export async function checkIfUserExistAction(_, payload) {
  const data = await this.$api.$post(URLS.CHECK_USER_EXIST, { email: payload });
  return Helpers.resolvePromise(data);
}

/**
 *  Set user login email
 * @param {function} commit - The vuex commit function
 * @param {String} payload - User email address
 * @return {String} Contain user email
 */
export function setUserLoginEmailAction({ commit }, payload) {
  commit(SET_AUTH_LOGIN_EMAIL, payload);
}

export function setJwtData({ commit }, payload) {
  commit(SET_JWT_DATA, payload);
  commit(SET_IS_TAPPTITUDE_USER, Boolean(payload?.accounts?.tapptitudeId ?? false));
}

/**
 * Reset state params that depends on Auth data.
 * @param {function} dispatch - The vuex dispatch function
 */
export function resetAuthData({ dispatch }) {
  this.$cookies.remove(COOKIES.token);
  dispatch('setUserLoginEmailAction', null);
  dispatch('setJwtData', null);
}

/**
 *  Set auth component name
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - Auth component name
 * @return {String} Auth component name
 */
export function setAuthComponentName({ commit }, payload) {
  commit(SET_AUTH_COMPONENT_NAME, payload);
}

/**
 *  Send magic link to existing users
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 */
export async function sendMagicLinkAction({ state }, args = {}) {
  const { email } = args;
  const { email: emailLogin } = state.login;

  try {
    const payload = {
      email: email || emailLogin,
      version: MAGIC_LINK_VERSION,
    };

    const { magicLink } = await this.$api.$post(URLS.SEND_MAGIC_LINK, payload);
    return { magicLink };
  } catch (error) {
    this.$sentry.captureException(error);
  }
}

export async function loginAction({ state }, args = {}) {
  const { email, phoneNumber, redirectUrl, redirectPath } = args;
  const { email: emailLogin } = state.login;
  const data = await this.$api.$post(URLS.LOGIN, {
    email: email || emailLogin,
    phoneNumber,
    redirectUrl,
    redirectPath,
  });
  return Helpers.resolvePromise(data);
}

/**
 *  Send magic link to existing users
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - User password
 * @return {Any} Response status
 */
export async function suggesticLoginAction({ state, commit }, payload) {
  const { email } = state.login;
  const { token } = await this.$login.$post(URLS.SUGGESTIC_LOGIN, { email, password: payload });
  commit(SET_AUTH_SUGGESTIC_TOKEN, token);
  return Helpers.resolvePromise(token);
}

/**
 *  Get magic link for existing users
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @return {Any} Response status
 */
export async function setMagicLink({ state, commit }, { email, source } = {}) {
  let magicLink = null;

  commit(SET_AUTH_MAGIC_LINK_FETCHING, true);
  try {
    const { suggesticToken } = state;
    const headers = { ...(suggesticToken && { Authorization: `Bearer ${suggesticToken}` }) };
    const payload = {
      ...(email && { email }),
      ...(source && { source }),
    };

    ({ magicLink } = await this.$api.$post(URLS.GET_MAGIC_LINK_V2, payload, headers));
  } catch (error) {
    this.$sentry.captureException(error);
    magicLink = null;
  }
  commit(SET_AUTH_MAGIC_LINK_FETCHING, false);
  commit(SET_AUTH_MAGIC_LINK, magicLink);

  return magicLink;
}

/**
 *  Request password reset that takes {email} and return a token string
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - User email address
 * @return {String} Contain user token
 */
export async function requestPasswordResetAction(_, payload) {
  const { data } = await this.$api.$post(URLS.REQUEST_PASSWORD_RESET, { email: payload });
  return Helpers.resolvePromise(data);
}

/**
 *  Set reset password token string
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - User token
 * @return {String} Contain user token
 */
export function setResetPasswordTokenAction({ commit }, payload) {
  commit(SET_AUTH_RESET_PASSWORD_TOKEN, payload);
}

/**
 *  Set reset password value
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - Password value
 * @return {String} Contain user password value
 */
export function setResetPasswordValueAction({ commit }, payload) {
  commit(SET_AUTH_RESET_PASSWORD_PASSWORD, payload);
}

/**
 *  Reset password reset that takes token and password and return status
 * @param {function} commit - The vuex commit function
 * @param {Object} state - The vuex state object
 * @param {String} payload - User email address
 * @return {String} Contain user token
 */
export async function resetPasswordAction({ state }) {
  const payload = state.resetPassword;
  try {
    const data = await this.$api.$post(URLS.RESET_PASSWORD, payload);
    return Helpers.resolvePromise(data);
  } catch (error) {
    this.$sentry.captureException(error);
    if (error.response && error.response.data && error.response.data.message) {
      throw new Error(JSON.stringify(error.response.data.message));
    } else {
      throw new Error('An error occurred while resetting the password.');
    }
  }
}
