export const getCheckoutIdByRegionAndProductHandler = (checkoutRepository) => ({
  execute: async (
    { region, vertical, type, pricingModel, brand, priceVariant = 0, variation = null },
    { onSuccess, onFailure },
  ) => {
    try {
      const response = await checkoutRepository.getCheckoutIdByRegionAndProductWithVariant({
        region: region.toUpperCase(),
        vertical: vertical.toLowerCase(),
        type: type.toLowerCase(),
        pricingModel: pricingModel.toLowerCase(),
        variant: priceVariant,
        variation,
        brand,
      });

      if (!response?.checkoutId) {
        onFailure(new Error('Checkout ID was not found for given params'));
        return;
      }

      onSuccess(response.checkoutId);
    } catch (error) {
      onFailure(error);
    }
  },
});
