import { EXPERIMENT_NAMES, QUIZ_VARIANTS } from '@/services/constants';

export default {
  name: EXPERIMENT_NAMES.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES,
  includedRoutes: [
    '/tour/yoga-28-day-challenge',
    '/tour/yoga-28-day-challenge/',
    '/quiz/ready-to-commit-yoga',
    '/quiz/ready-to-commit-yoga/',
  ],
  variants: [
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE}`,
      expVariant: null,
    },
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V1}`,
      expVariant: '4606-1',
    },
    {
      tour: QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2,
      path: `/tour/${QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE_FULL_BODY_BEFORE_AFTER_IMAGES_V2}`,
      expVariant: '4606-2',
    },
  ],
};
