import { getBrandByHost, getCdnHostByBrand, getImageHostByBrand } from './utils';

export default function ({ app, req, $config }, inject) {
  const getHost = () => {
    return process.client ? window.location.hostname : req.headers['x-original-host'] || req.headers.host;
  };

  const host = getHost();
  const brand = getBrandByHost({ host });
  const selectedHost = getImageHostByBrand({ brand, isCi: $config?.isCI, host });
  const selectedCdnHost = getCdnHostByBrand({ brand });

  const getLocaleLanguageCode = () => {
    return (
      app.i18n.locales?.find((locale) => locale.code === app.i18n.defaultLocale)?.acceptedLanguage?.substr(0, 2) || 'en'
    );
  };

  const localeLanguageCode = getLocaleLanguageCode();

  /**
   * Gets the URL of an image, with optional Cloudflare image processing options.
   *
   * @param {string} imageName - The name of the image.
   * @param {Object|null} [imageOptions=null] - Cloudflare image processing options.
   * @returns {string} The image URL with applied options if necessary.
   */
  const getImage = (imageName, imageOptions = null) => {
    let options = '';
    const isLocalized = imageOptions?.isLocalized ?? true;

    const imageUrl = isLocalized
      ? `${selectedHost}/images/${localeLanguageCode}/${imageName}`
      : `${selectedHost}/images/${imageName}`;

    if (imageOptions && !getHost().includes('localhost')) {
      options = parseImageOptions(imageOptions);
      return `${selectedCdnHost}${options}/${imageUrl}`;
    }

    return imageUrl;
  };

  const parseImageOptions = (obj) => {
    return Object.keys(obj)
      .map((key) => `${key}=${obj[key]}`)
      .join(',');
  };

  inject('getImage', getImage);
}
