import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

const IS_DATADOG_ENABLED = true;
const IS_DATADOG_RUM_ENABLED = true;
const isProd = process.env.NUXT_ENV_APP_ENV === 'production';

const initDatadog = ({ appVersion }) => {
  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'staging') return;

  const sharedDatadogConfig = {
    clientToken: 'pub591f5ad34509223dac1e73d3cf960a6f',
    site: 'datadoghq.eu',
    service: isProd ? 'web' : 'web-staging',
    env: process.env.NODE_ENV,
    version: appVersion,
  };

  if (IS_DATADOG_RUM_ENABLED && isProd) {
    datadogRum.init({
      ...sharedDatadogConfig,
      applicationId: '45f3b46e-7d87-48ac-9c77-4d788e93ff8d',
      sessionSampleRate: 1,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingUrls: [/https:\/\/us-central1-reversehealth-(dev|web)\.cloudfunctions\.net/],
    });
  }

  datadogLogs.init({
    ...sharedDatadogConfig,
    sessionSampleRate: 100,
    forwardErrorsToLogs: false,
  });
};

export default ({ $config }) => {
  if (IS_DATADOG_ENABLED && !process.env.IS_CI && !process.env.CI) {
    initDatadog({
      appVersion: $config.appVersion,
    });
  }
};
