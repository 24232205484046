import { parse as parseCookie, serialize as serializeCookie } from 'cookie';
import experiments from '../experiments';
import { useDomain } from '@/composables/useDomain';
import { PROJECT_BRANDS } from '@/modules/brand/domain/brand';

const DEFAULT_MAX_AGE = 60 * 60 * 24 * 7;
const BOT_EXPRESSION = /(bot|spider|crawler)/i;

/**
 * [ABSTRACT CLASS] Base class to allocate experiments and get the currently active experiments.
 *  - Can allocate multiple A/B tests for each url
 *  - Support (Multiple) MVT experiments
 *  - Allocation can happens either in Nuxt or in a CloudFlare worker (configurable)
 */
export class ABTesting {
  constructor(kvExperiments = []) {
    this.kvExperiments = kvExperiments;
  }

  getAllActiveExperiments() {
    const cookies = this.getAllCookies();

    if (!cookies) {
      return [];
    }

    const activeExperiments = [];
    const expCookiesNames = Object.keys(cookies).filter((k) => k.startsWith('exp-'));

    for (const name of expCookiesNames) {
      const cookie = cookies[name];
      const parts = cookie.split('.');
      const expId = parts[0];
      const expVariants = parts[1].split('-').map((v) => parseInt(v));
      const experiment = experiments.find((e) => e.name === expId);
      const kvExperiment = this.kvExperiments.find((kvExperiment) => kvExperiment.name === expId);

      if (!experiment) {
        console.warn('Experiment not available anymore', expId);
        continue;
      }

      if (!kvExperiment) {
        console.warn('Experiment configuration not found', expId);
        continue;
      }

      if (!kvExperiment.enabled) {
        console.warn('Experiment not enabled', expId);
        continue;
      }

      const variantIndexes = expVariants.filter((v) => {
        if (!kvExperiment.variants?.includes(v)) {
          console.warn('Experiment variant not enabled', expId, expVariants, kvExperiment.variants);
          return false;
        }

        const variant = experiment.variants[v];

        if (!variant) {
          console.warn('Experiment variant not available anymore', expId, expVariants);
          return false;
        }

        return true;
      });

      if (!variantIndexes.length) {
        console.warn('Experiment not enabled by missing variant', variantIndexes);
        continue;
      }

      const variants = variantIndexes.map((i) => experiment.variants[i]);

      activeExperiments.push({
        experiment,
        variants,
        variantIndexes,
      });
    }

    return activeExperiments;
  }

  static getUserExperimentsFromCookies() {
    let experimentUserCookies = null;

    // get available cookies. Ctx nor process is available here
    const cookieStr = document ? document.cookie : null;
    const cookies = parseCookie(cookieStr || '') || {};
    // filter cookies. Getting just exp-* named cookies
    const expCookiesNames = Object.keys(cookies).filter((k) => k.startsWith('exp-'));

    for (const name of expCookiesNames) {
      // const cookie gets the value of the exp- cookie.
      // Value = $expId.$variant, eg: 10KwqJsbRwKZlWPTwbjtFA.0
      const expIdObject = cookies[name].split('.');
      let expId = null;
      let variant = null;
      // split the ExpId from the value
      if (expIdObject.length) {
        expId = expIdObject[0];
        variant = expIdObject[1];
      }
      // store experiment id following the structure:
      // { experimentId: variant}
      experimentUserCookies = { ...experimentUserCookies, [expId]: variant };
    }
    return experimentUserCookies;
  }

  allocate(variant) {
    if (this.skipAssignment()) {
      return [];
    }

    const activeExperiments = [];
    let notAllocatedExperiments = [];
    const expCookie = (expID) => `exp-${expID}`;

    if (!isNaN(variant)) {
      /* cause we are forcing variant so we wan to get the 
      allocated ones as well so that we can force the the paratmer */
      notAllocatedExperiments = experiments.filter((e) => this.checkAllocationEligibility(e));
    } else {
      notAllocatedExperiments = experiments
        .filter((e) => !this.getCookie(expCookie(e.name)))
        .filter((e) => this.checkAllocationEligibility(e));
    }

    for (const experiment of notAllocatedExperiments) {
      const kvExperiment = this.kvExperiments.find((kvExperiment) => kvExperiment.name === experiment.name);

      if (kvExperiment) {
        const variantIndexes = [];
        const variants = [];
        while (variantIndexes.length < (experiment.sections || 1)) {
          // if the variant is there it will select it or else it will select random variant
          const index = kvExperiment.variants.includes(variant)
            ? variant
            : kvExperiment.variants[Math.floor(Math.random() * kvExperiment.variants.length)];
          variantIndexes.push(index);
          variants.push(experiment.variants[index]);
        }

        // Set exp-<exp ID> cookie:
        const cookieName = expCookie(experiment.name);
        const cookieValue = experiment.name + '.' + variantIndexes.join('-');

        this.setCookie(cookieName, cookieValue, experiment.maxAge);

        // Add to list of all active variants:
        activeExperiments.push({
          experiment,
          variants,
          variantIndexes,
        });
        console.log(`Allocated E: ${experiment.name} V: ${variantIndexes.join('-')}`);
      }
    }
    return activeExperiments;
  }

  getRandomNumberExperimentFromCookies() {
    let rndExperimentPerc = this.getCookie('rnd-experiment-perc');

    if (!rndExperimentPerc) {
      rndExperimentPerc = Math.floor(Math.random() * 100) + 1;
      const cookieName = 'rnd-experiment-perc';
      this.setCookie(cookieName, rndExperimentPerc);
    }
    return rndExperimentPerc;
  }

  // Abstract methods:
  skipAssignment() {}

  checkAllocationEligibility(experiment) {}

  checkEligibility(experiment) {}

  getAllCookies() {}

  getCookie(name) {}

  setCookie(name, value, maxAge = DEFAULT_MAX_AGE) {}
}

/**
 * Nuxt compatibility layer to support allocation on either server or client.
 */
export class ABTestingNuxt extends ABTesting {
  constructor(ctx) {
    super(ctx.$kvExperiments);
    this.ctx = ctx;
  }

  createExp() {
    return new Experiments(this.getAllActiveExperiments(), this.ctx);
  }

  sendToAnalytics(experiments, gtag = false) {
    const handler = gtag ? '$gtag' : '$gtm';

    if (process.server || !this.ctx[handler]) {
      return;
    }
    for (const e of experiments) {
      console.log(`E: ${e.experiment.name} V: ${e.variantIndexes.join('-')}`, e);
      const brand = this.ctx.store?.state?.quiz?.product?.brand || PROJECT_BRANDS.reverse;
      if (gtag) {
        this.ctx.$gtag.event('experiment_impression', {
          experiment_id: e.experiment.name,
          variant_id: e.variantIndexes.join('-'),
          brand: brand,
        });
      } else {
        this.ctx.$gtm.push({
          event: 'experiment_impression',
          experiment_id: e.experiment.name,
          variant_id: e.variantIndexes.join('-'),
          brand: brand,
        });
      }
    }
  }

  // Private methods:
  skipAssignment() {
    if (process.server) {
      return (
        this.ctx.req &&
        this.ctx.req.headers &&
        this.ctx.req.headers['user-agent'] &&
        this.ctx.req.headers['user-agent'].match(BOT_EXPRESSION)
      );
    }

    return navigator.userAgent.match(BOT_EXPRESSION);
  }

  checkAllocationEligibility(experiment) {
    if (!experiment.allocateOnClient) {
      return;
    }
    return this.checkEligibility(experiment);
  }

  checkEligibility(experiment) {
    const kvExperiment = this.kvExperiments.find((kvExperiment) => kvExperiment.name === experiment.name);
    const quizVariant = this.ctx.store.state.quiz.quizVariant;

    if (!kvExperiment) {
      return false;
    }

    const rndExperimentPerc = this.getRandomNumberExperimentFromCookies();
    const isEnabled = kvExperiment.enabled;
    const matchRoute = experiment.includedRoutes.includes(this.ctx.route.path);
    const matchUtmSource = !experiment.excludedUtmSources?.includes(this.ctx.route.query.utm_source);
    const matchCountry = kvExperiment.regions.includes(this.ctx.$countryCode);
    const matchPercentage = rndExperimentPerc < kvExperiment.percentage;
    const experimentTours = kvExperiment.tour_ids || [];
    const isTourAllowed = experimentTours.length === 0 || experimentTours.includes(quizVariant);
    const isElegible =
      !experiment.isElegible || (experiment.isElegible && typeof experiment.isElegible !== 'function')
        ? true
        : experiment.isElegible(this.ctx);

    return isEnabled && matchRoute && matchUtmSource && matchCountry && matchPercentage && isElegible && isTourAllowed;
  }

  /**
   * Filters experiments based on the user's tour ID (quizVariant).
   * Removes cookies for experiments that are not allowed for the current tour ID.
   *
   * @param {Array} experiments - The list of experiments to filter.
   * @returns {Array} The filtered list of experiments allowed for the current tour ID.
   */
  filterByTourId(experiments) {
    const quizVariant = this.ctx.store.state.quiz.quizVariant || '';

    return experiments.filter((e) => {
      const kvExperiment = this.ctx.$kvExperiments.find((kvExperiment) => {
        return kvExperiment.name === e.experiment.name;
      });

      const isTourAllowed = kvExperiment?.tour_ids?.includes(quizVariant) ?? true;

      if (!isTourAllowed) {
        this.clearExperimentCookie(e.name);
      }

      return isTourAllowed;
    });
  }

  getAllCookies() {
    const cookieStr = process.client ? document.cookie : this.ctx.req.headers.cookie;
    const cookies = parseCookie(cookieStr || '') || {};
    return cookies;
  }

  getCookie(name) {
    if (process.server && !this.ctx.req) {
      return;
    }

    const cookies = this.getAllCookies();
    return cookies[name];
  }

  setCookie(name, value, maxAge = DEFAULT_MAX_AGE) {
    const serializedCookie = serializeCookie(name, value, {
      path: '/',
      maxAge,
    });

    if (process.client) {
      // Set in browser
      document.cookie = serializedCookie;
    } else if (process.server && this.ctx.res) {
      // Send Set-Cookie header from server side
      if (!this.ctx.res.headersSent) {
        const prev = this.ctx.res.getHeader('Set-Cookie');
        let value = serializedCookie;
        if (prev) {
          value = Array.isArray(prev) ? prev.concat(serializedCookie) : [prev, serializedCookie];
        }
        this.ctx.res.setHeader('Set-Cookie', value);
      }
    }
  }

  /**
   * Clears the cookie for the given experiment name.
   *
   * @param {string} experimentName - The name of the experiment.
   */
  clearExperimentCookie(experimentName) {
    const cookieName = `exp-${experimentName}`;
    try {
      this.setCookie(cookieName, '', 0);
    } catch (err) {
      throw new Error('Error clearing experiment cookie');
    }
  }
}

/**
 * Nuxt mixin variable to get the currently active experiments and variants
 */
export class Experiments {
  experiments = [];

  constructor(experiments, ctx) {
    this.experiments = experiments;
    this.ctx = ctx;
  }

  /**
   * Return the list of active experiments for the current page
   * @returns {experiment, variant, activeVariantIndex, *[]}, The active experiments for the current page
   */
  get activeExperiments() {
    if (!this.ctx.route) {
      return [];
    }

    const abTesting = new ABTestingNuxt(this.ctx);
    const rndExperimentPerc = abTesting.getRandomNumberExperimentFromCookies();

    return this.experiments.filter((e) => {
      const kvExperiment = this.ctx.$kvExperiments.find((kvExperiment) => {
        return kvExperiment.name === e.experiment.name;
      });

      if (!kvExperiment) {
        return false;
      }

      const isEnabled = kvExperiment.enabled;
      const matchRoute = e.experiment.includedRoutes.includes(this.ctx.route.path);
      const matchUtmSource = !e.experiment.excludedUtmSources?.includes(this.ctx.route.query.utm_source);
      const matchCountry = kvExperiment.regions.includes(this.ctx.$countryCode);
      const matchPercentage = rndExperimentPerc < kvExperiment.percentage;

      return isEnabled && matchRoute && matchUtmSource && matchCountry && matchPercentage;
    });
  }

  /**
   * Return the list of the active CSS classes for the currently active experiments and variants
   */
  get $classes() {
    return this.activeExperiments.map((e) => e.variantIndexes.map((v) => 'exp-' + e.experiment.name + '-' + v)) || [];
  }

  /**
   * Return if the given experiment/variant is active on the current url/page
   */
  isVariantActive(expId, variant) {
    const exp = this.experiments.find((e) => e.experiment.name === expId);
    if (!exp) {
      return false;
    }
    return exp.variantIndexs.includes(variant);
  }
}

/**
 * CloudFlare workers compatibility layer to allocate users
 */
export class ABTestingCFWorker extends ABTesting {
  serializedCookies = [];

  constructor(request, kvExperiments) {
    super(kvExperiments);
    this.request = request;
  }

  // Private methods:
  skipAssignment(ctx) {
    const agent = this.request.headers.get('User-Agent');
    return agent && agent.match(BOT_EXPRESSION);
  }

  checkAllocationEligibility(experiment) {
    if (experiment.allocateOnClient) {
      return;
    }

    return this.checkEligibility(experiment);
  }

  getCountryCode() {
    const hostname = this.request.headers.get('x-original-host') || this.request.headers.get('host');

    const domain = useDomain(hostname);
    const countryCode = domain.getCountryCode();

    return countryCode;
  }

  checkEligibility(experiment) {
    const url = new URL(this.request.url);
    const ctx = {
      route: {
        url: this.request.url,
        path: url.pathname,
        query: Object.fromEntries(new URLSearchParams(url.search)),
      },
      request: this.request,
    };
    const kvExperiment = this.kvExperiments.find((kvExperiment) => kvExperiment.name === experiment.name);

    if (!kvExperiment) {
      return false;
    }

    const rndExperimentPerc = this.getRandomNumberExperimentFromCookies();
    const isEnabled = kvExperiment.enabled;
    const matchRoute = experiment.includedRoutes.includes(ctx.route.path);
    const matchUtmSource = !experiment.excludedUtmSources?.includes(ctx.route.query.utm_source);
    const matchCountry = kvExperiment.regions.includes(this.getCountryCode());
    const matchPercentage = rndExperimentPerc < kvExperiment.percentage;
    const isElegible =
      !experiment.isElegible || (experiment.isElegible && typeof experiment.isElegible !== 'function')
        ? true
        : experiment.isElegible(ctx);

    return isEnabled && matchRoute && matchUtmSource && matchCountry && matchPercentage && isElegible;
  }

  getAllCookies() {
    return parseCookie(this.request.headers.get('Cookie') || '') || {};
  }

  getCookie(name) {
    const cookies = this.getAllCookies();
    return cookies[name];
  }

  setCookie(name, value, maxAge = DEFAULT_MAX_AGE) {
    const serializedCookie = serializeCookie(name, value, {
      path: '/',
      maxAge,
    });
    this.serializedCookies.push(serializedCookie);
  }
}
