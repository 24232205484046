import { getBrandByHost, getImageHostByBrand } from './utils';

export default function ({ req, $config }, inject) {
  const getHost = () => {
    return process.client ? window.location.hostname : req.headers['x-original-host'] || req.headers.host;
  };

  const host = getHost();
  const brand = getBrandByHost({ host });
  const selectedHost = getImageHostByBrand({ brand, isCi: $config?.isCI, host });

  const getIcon = (iconName) => {
    return `${selectedHost}/icons/${iconName}`;
  };

  inject('getIcon', getIcon);
}
